/**
 * @file    config/index.ts - Exports global configuration values used in the
 *          web app.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023
 */

import * as v from 'valibot';

const UrlSchema = v.pipe(v.string(), v.url());
const StringSchema = v.string();
const OptionalStringSchema = v.optional(StringSchema);

/**
 * The base pathname the web app will be served under.
 */
export const BASE_PATH = v.parse(v.string(), process.env.NEXT_PUBLIC_BASE_PATH);

/**
 * The scheduler to get `dcp-client` from.
 */
export const DCP_SCHEDULER_LOCATION = v.parse(
  UrlSchema,
  process.env.NEXT_PUBLIC_DCP_SCHEDULER_LOCATION,
);

/**
 * The address of the bank account the worker will pay to by default.
 */
export const DEFAULT_BANK_ACCOUNT = v.parse(
  StringSchema,
  process.env.NEXT_PUBLIC_DEFAULT_BANK_ACCOUNT,
);

/**
 * The Google Analytics Tracking ID for the web app. A value of `undefined`
 * indicates that the use of Google Analytics should be disabled.
 */
export const GA_TRACKING_ID = v.parse(
  OptionalStringSchema,
  process.env.NEXT_PUBLIC_GA_ID,
);

/**
 * The git revision of the app when it was built.
 */
export const GIT_REV: string = v.parse(
  StringSchema,
  process.env.NEXT_PUBLIC_GIT_REV,
  {
    message: 'Missing Git Revision.',
  },
);

export const DEFAULT_JOIN_KEY = 'public';
